
/// <reference path="./noise.d.ts" />
import fragmentShaderSource from "./noise.frag";

const vertexShaderSource = `
attribute vec2 aVertexPosition;
void main() {
  gl_Position = vec4(aVertexPosition, 0, 1);
}`;

let prefersReducedMotion = false;
const darkBackgroundColor = [17.0 / 256.0, 18.0 / 256.0, 29.0 / 256.0, 1] as [number, number, number, number]; // rgb(17, 18, 29)
const lightBackgroundColor = [238.0 / 256.0, 237.0 / 256.0, 226.0 / 256.0, 1] as [number, number, number, number]; // rgb(238, 237, 226)
let backgroundColor = darkBackgroundColor;

const foregroundColor = [37.0 / 256.0, 218.0 / 256.0, 134.0 / 256.0, 1.0] as const; // rgb(37, 218, 134)

function setTheme(light: boolean) {
    backgroundColor = light ? lightBackgroundColor : darkBackgroundColor;
};

function loadShader(gl: WebGLRenderingContext, type: number, source: string): WebGLShader | null {
    const shader = gl.createShader(type);
    if (!shader) return null;
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.warn('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

function createProgram(gl: WebGLRenderingContext): WebGLProgram | null {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
    if (!vertexShader) return null;
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
    if (!fragmentShader) return null;

    const program = gl.createProgram();
    if (!program) return null;

    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.warn('Unable to initialize the shader program: ' + gl.getProgramInfoLog(program));
        return null;
    }

    return program;
}

function renderBacksplash(options: { width?: number, height?: number, animate?: boolean } = {}): boolean {
    const backsplash = document.getElementById("backsplash") as HTMLCanvasElement | null;
    if (!backsplash) return false;

    const animate = options.animate ?? true;

    const gl = backsplash.getContext("webgl", {
        antialias: false,
        preserveDrawingBuffer: !animate
    });

    if (!gl) return false;

    backsplash.width = options.width ?? backsplash.clientWidth;
    backsplash.height = options.height ?? backsplash.clientHeight;

    if (!options.width || !options.height) window.addEventListener('resize', () => {
        backsplash.width = options.width ?? backsplash.clientWidth;
        backsplash.height = options.height ?? backsplash.clientHeight;
    });

    let buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array([
            -1.0, -1.0,
            1.0, -1.0,
            -1.0, 1.0,
            -1.0, 1.0,
            1.0, -1.0,
            1.0, 1.0]),
        gl.STATIC_DRAW
    );

    const program = createProgram(gl);
    if (!program) return false;

    gl.useProgram(program);

    const startTime = Date.now();

    const programInfo = {
        program,
        attribLocations: {
            vertexPosition: gl.getAttribLocation(program, "aVertexPosition")
        },
        uniformLocations: {
            inputResolution: gl.getUniformLocation(program, "iResolution"),
            inputTime: gl.getUniformLocation(program, "iTime"),
            inputBackgroundColor: gl.getUniformLocation(program, "iBackgroundColor"),
            inputForegroundColor: gl.getUniformLocation(program, "iForegroundColor")
        },
    };

    function render() {
        if (!gl || !backsplash) return;
        gl.viewport(0, 0, backsplash.width, backsplash.height);
        gl.clearColor(0, 0, 0, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
        gl.vertexAttribPointer(programInfo.attribLocations.vertexPosition, 2, gl.FLOAT, false, 0, 0);
        gl.uniform3f(programInfo.uniformLocations.inputResolution, backsplash.width, backsplash.height, 0.0);
        gl.uniform4f(programInfo.uniformLocations.inputBackgroundColor, ...backgroundColor);
        gl.uniform4f(programInfo.uniformLocations.inputForegroundColor, ...foregroundColor);
        gl.uniform1f(programInfo.uniformLocations.inputTime, (Date.now() - startTime) / 1000);

        gl.drawArrays(gl.TRIANGLES, 0, 6);

        if (animate && !prefersReducedMotion) {
            window.requestAnimationFrame(render);
        }
    }

    render();
    return true;
}

function run() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    prefersReducedMotion = mediaQuery.matches;
    mediaQuery.addEventListener('change', () => prefersReducedMotion = mediaQuery.matches);

    const lightTheme = window.matchMedia('(prefers-color-scheme: light)');
    setTheme(lightTheme.matches);
    lightTheme.addEventListener('change', () => setTheme(lightTheme.matches));

    renderBacksplash();
}

document.addEventListener('DOMContentLoaded', run, false);
